<template lang="pug">
  Section.cy-display-importance-section(
    contains-dropdown
    :title="$t('plan_settings.basic_settings.sections.display_importance.section_name')"
  )
    .row.w-100.no-gutters
      .col
        AppDropdown.cy-display-importance(
          order-direction="keep"
          close-on-select
          :placeholder="$t('actions.select')"
          :items="items"
          :value="object.$model"
          @select="object.$model = $event"
        )
      .col
</template>

<script>
  import { DISPLAY_IMPORTANCE_OPTIONS } from "@/config/constants"

  export default {
    props: {
      object: Object
    },

    data() {
      return {
        items: DISPLAY_IMPORTANCE_OPTIONS
      }
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    }
  }
</script>
